@font-face {
  font-family: "Noto-Sans-EN-Regular";
  src: url("../assets/fonts/notosans/Noto-Sans-EN-Regular.ttf");
}

@font-face {
  font-family: "Noto-Sans-KH-Regular";
  src: url("../assets/fonts/notosans/Noto-Sans-KH-Regular.ttf");
}

// NotoSansKhmer Fonts

@font-face {
  font-family: "NotoSansKhmer";
  src: url("../assets/fonts/notosans/NotoSansKhmer.ttf");
} //NotoSansKhmer-VariableFont_wdth,wght.ttf

@font-face {
  font-family: "NotoSansKhmer-Regular";
  src: url("../assets/fonts/notosans/NotoSansKhmer-Regular.ttf");
}

@font-face {
  font-family: "NotoSansKhmer-Bold";
  src: url("../assets/fonts/notosans/NotoSansKhmer-Bold.ttf");
}

@font-face {
  font-family: "NotoSansKhmer-Light";
  src: url("../assets/fonts/notosans/NotoSansKhmer-Light.ttf");
}

@font-face {
  font-family: "NotoSansKhmer-Medium";
  src: url("../assets/fonts/notosans/NotoSansKhmer-Medium.ttf");
}

// Other Fonts

@font-face {
  font-family: "Siemreap-Regular";
  src: url("../assets/fonts/otherfont/Khmer OS Siemreap.ttf");
}

@font-face {
  font-family: "Krasar";
  src: url("../assets/fonts/otherfont/Krasar Regular.ttf");
}

@font-face {
  font-family: "Noto-Serif";
  src: url("https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap");
}

@font-face {
  font-family: "PT Serif Caption";
  src: url("https://fonts.googleapis.com/css2?family=PT+Serif+Caption&display=swap");
}

@font-face {
  font-family: "Hanuman";
  src: url("../assets/fonts/hanuman/Hanuman-Regular.ttf");
} 
@font-face {
  font-family: "HanumanBold";
  src: url("../assets/fonts/hanuman/Hanuman-Bold.ttf");
} 
@font-face {
  font-family: "HanumanBlack";
  src: url("../assets/fonts/hanuman/Hanuman-Black.ttf");
} 
@font-face {
  font-family: "HanumanLight";
  src: url("../assets/fonts/hanuman/Hanuman-Light.ttf");
} 
@font-face {
  font-family: "HanumanThin";
  src: url("../assets/fonts/hanuman/Hanuman-Thin.ttf");
} 
