// .ant-table-wrapper {
//   background: white;
//   padding: 16px 8px;
//   box-shadow: 0 4px 8px 0 rgba(145, 158, 171, .24);;
// }

.ant-table-pagination.ant-pagination {
  margin: 16px !important;
}

.ant-input-suffix {
  line-height: 1 !important;
}
.ant-drawer-body {
  padding: 0px !important;
}
.ant-drawer-content-wrapper {
  width: 238px !important;
  max-width: 238px !important;
  min-width: 238px !important;
}

// .ant-spin-nested-loading .ant-spin-blur {
//   z-index: 99999;
//   // opacity: 0.1;
// }
