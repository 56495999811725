@import "fonts";
@import "variables";
@import "utilities";
@import "editable";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Hanuman',  serif ,  Helvetica , Arial , sans-serif  , "Segoe UI" , -apple-system , BlinkMacSystemFont , Roboto , "Apple Color Emoji" , /* Emojis*/ "Segoe UI Emoji" , /* Emojis*/ "Segoe UI Symbol" ; /* Emojis*/ 
}

.d-none {
  display: none !important;
}

.ant-select-selection-item {
  .color-box {
    margin-top: 5px;
  }

  .img-select {
    margin-top: -5px;
    // width: 25px;
    // height: 25px;
  }
}

$base-color: #036;

@for $i from 1 through 1000 {
  .width_#{$i} {
    width: #{$i}px;
  }
}