@import "fonts";
@import "variables";
@import "utilities";
@import "editable";
@import url('https://fonts.googleapis.com/css2?family=PT+Serif+Caption&display=swap');

.answer-box{
    background: lightblue;
    border-radius: 20px;
    padding: 5px;
    font-family: 'PT Serif Caption', serif;
    margin-top: 5px;
    border: 2px solid grey;
    box-shadow: .1rem .1rem 0 grey;
    @import url('https://fonts.googleapis.com/css2?family=PT+Serif+Caption&display=swap');
    overflow-x: auto;
}